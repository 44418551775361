.inline {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.inline > p {
  margin-bottom: 0;
}
.batch > .ui.contrast {
  margin-left: 0.6rem;
}

.legend img {
  width: 1.24rem;
  padding-top: 0.10rem;
  padding-right: 0.4rem;
}

.recover {
  cursor: pointer;
}
.recover:hover {
  color: rgba(225, 225, 225, 0.8);;
}
.recover:active {
  color: rgba(162, 162, 162, 0.8);;
}