.chip-segment {
  max-height: 300px;
  overflow-y: auto;
}
.chip-segment > .ui.label {
  margin-right: 6px;
  margin-bottom: 6px;
}
.two-columns {
  display: flex;
}
.left-column {
  flex-basis: 230px;
  padding: 1em;
}
.right-column {
  flex-grow: 1;
  padding: 1em;
}
.app .header-menu .item.main-logo>img.main-logo-img:not(.ui) {
  margin-right: 1rem;
}
.header-menu h2.ui.header {
  margin-top: 0;
}

.left-column .ui.secondary.vertical.pointing.menu .menu .item {
  font-size: 1em;
  padding: 1.5em 2em !important;
}
.ui.secondary.vertical.pointing.menu a.item {
  border-right-color: rgba(34,36,38,.15);
}

.left-column .ui.secondary.vertical.pointing.menu {
  border-right-color: transparent;
}

.table-row-selected {
  cursor: pointer;
}